const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '',
  title: 'Nobody',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Nobody',
  role: 'Machine Learning Enthusiast & Automation Addict',
  description:
    'I am a Python developer with a keen interest in Machine Learning & Automation. I thrive in a fast-paced environment where I can learn and grow as a developer. I am constantly learning new stuff. I am currently exploring cloud computing and cybersecurity.',
  resume: '/resumelol.pdf',
  social: {
    linkedin: 'https://linkedin.com/in/null',
    github: 'https://github.com/null',
    facebook: 'https://www.facebook.com/null',
    instagram: 'https://www.instagram.com/null',
    tweet: 'https://twitter.com/null',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'GodsEye',
    description:
      'GodsEye is a Python built Software that uses a machine learning model to recognize users using their face. This project was showcased on  “[Some-Place]”, a [Something].',
    stack: ['Python', 'OpenCV', 'Jupyter Notebook'],
    sourceCode: 'https://github.com/Subibhu/GodsEye',
    livePreview: 'https://youtu.be/dQw4w9WgXcQ',
  },
  {
    name: 'AccHub',
    description:
      'AccHub a Telgram bot that can automate account creation. It uses Pyrogram API to deliver results to the User..',
    stack: ['Python', 'Pyrogram', 'Jupyter Notebook'],
    sourceCode: 'https://github.com/Subibhu/AccHub',
    livePreview: 'https://youtu.be/dQw4w9WgXcQ',
  },
  {
    name: 'Some Project',
    description:
      "Hmm Very Interesting Description",
    stack: ['Python', 'JavaScript', 'MongoDB'],
    sourceCode: 'https://youtu.be/dQw4w9WgXcQ',
    livePreview: 'https://youtu.be/dQw4w9WgXcQ',
  },
  {
    name: 'Interesting Name',
    description:
      'hmmmmmmmmmmmm very interesting',
    stack: ['Python', 'JavaScript'],
    sourceCode: 'https://youtu.be/dQw4w9WgXcQ',
    livePreview: 'https://youtu.be/dQw4w9WgXcQ',
  },
  {
    name: 'Very Creative Name',
    description:
      'Very Creative Name Indeed',
    stack: ['Python','Py-Tg-Bot'],
    sourceCode: 'https://youtu.be/dQw4w9WgXcQ',
    livePreview: 'https://youtu.be/dQw4w9WgXcQ',
  },
]

const certifications = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Certificate 1',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Certificate 2',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Certificate 3',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python',
  'Machine Learning',
  'Penetration Testing',
  'Microcontroller Programming',
  'Cloud Computing[AWS,GCP,OCI,Azure]',
  'Git',
  'MongoDB',
  'Automation',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'subibhu@subibhu.info.np',
  phone: '+9770000000000',
  address: 'Pokhara, Nepal',
}

export { header, about, projects, skills, contact, certifications }
