import React, { useState } from 'react';
import { contact } from '../../portfolio';
import './Contact.css';

const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      // Perform any necessary form validation here before submitting
      const response = await fetch('contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email }),
      });
      if (response.ok) {
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          setShowPopup(false);
        }, 3000); // Reset the form and hide popup after 3 seconds
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);
    }
  };

  return (
    <section className='section contact center' id='contact'>
      <h2 className='section__title'>Contact</h2>
      <button className='btn btn--outline' onClick={() => setShowPopup(true)}>
        Email me
      </button>

      {showPopup && (
        <div className='popup-overlay'>
          <div className='popup'>
            <span className='popup__close' onClick={() => setShowPopup(false)}>
              &times;
            </span>
            <form onSubmit={handleFormSubmit}>
              <div className='form-group'>
                <label htmlFor='name'>Name  : </label>
                <input
                  type='text'
                  id='name'
                  name='name'
                  value={name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='email'>Email  : </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button type='submit' className='btn btn--outline'>
                Submit
              </button>
            </form>
            {submitted && <div className='submitted'>Submitted</div>}
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
